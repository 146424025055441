import React, { useState } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import toast from "react-hot-toast";
import { validate } from "react-email-validator";
import ApiService from "../../ApiService";
import { motion } from "framer-motion";

const SubscribeFooter = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    const email = event.target.value.trim();
    setEmail(email);
  };

  const handleEmailSubmit = async () => {
    if (!validate(email)) {
      toast.error("Invalid Email");
      return;
    }

    // submit email to backend
    const api = new ApiService();
    const { error, msg } = await api.post(`email/submit`, { email });
    if (error) {
      toast.error(msg);
      return;
    } else if (!error && msg) {
      toast.success(msg);
      setEmail("");
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="footer_form">
          <Input
            type="text"
            className="form-control footer-subscribe email-input footer-form_text-field w-input"
            placeholder="Email"
            onChange={handleEmailChange}
            value={email}
          />
          <Input
            type="submit"
            value=""
            className="footer_form-submit w-button"
            onClick={handleEmailSubmit}
          ></Input>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscribeFooter;
