import React, { Component, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { motion } from "framer-motion";
//Import Images
import homeImg from "../../assets/images/home-img.png";
import homeVidOverlay from "../../assets/images/hero-video-overlay.png";
import techBgImg from "../../assets/images/bg-tech.jpg";
import techBgVid from "../../assets/images/bg-tech3.mp4";

function BgVideo() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <>
      <video
        ref={videoRef}
        className="hero-bg-video"
        autoplay={true}
        loop={true}
        muted={true}
        playsinline={true}
        poster={techBgImg}
      >
        <source src={techBgVid} type="video/mp4"></source>
      </video>
      <img src={homeVidOverlay} className="hero-bg-video-overlay" />
    </>
  );
}

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="hero-section index" id="home">
          <BgVideo />
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <motion.div
                  animate={{
                    scale: [0, 1.07, 1],
                    opacity: [0, 1],
                    x: [-5, 0],
                  }}
                  className="h-100"
                >
                  <div className="d-flex flex-column justify-space-between h-100 pb-4">
                    <div>
                      <p className="font-16 text-uppercase">
                        NO DEVS, NO PROBLEM
                      </p>
                      <h1 className="hero-title mb-4">
                        {/* Build Better Web3 */}
                        The User Friendly Web3 Development Platform
                        {/* <span className="text-primary">Invoza</span> */}
                      </h1>
                      <p>
                        Better Blocks is on a mission to be the largest creator
                        of value for Web3 Projects & Communities. For founders,
                        builders, and collectors our easy to use platform
                        delivers gamechanging improvement in project utility,
                        extensibility, and scalability. Join us as we make the
                        blockchain projects you love… better!
                      </p>
                    </div>
                    <div className="mt-1">
                      <a
                        href="https://app.betterblocks.io"
                        className="btn btn-primary btn-lg mt-2 mr-2"
                      >
                        Get Started For Free
                      </a>
                    </div>
                  </div>
                </motion.div>
              </Col>
              <Col lg={6} sm={8}>
                <motion.div
                  animate={{
                    scale: [0, 1.07, 1],
                    opacity: [0, 1],
                    x: [5, 0],
                  }}
                >
                  <div className="home-img mt-5 mt-lg-0">
                    <img
                      src={homeImg}
                      alt=""
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
