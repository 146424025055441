import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Section Title
import SectionTitle from "../common/section-title";
import Counter from "../../components/Counter/counter";
import ClientBox from "./client-box";

//Import Images
import clinet1 from "../../assets/images/clients/1.png";
import clinet3 from "../../assets/images/clients/3.png";
import clinet4 from "../../assets/images/clients/4.png";
import clinet6 from "../../assets/images/clients/6.png";
import twitterImg from "../../assets/images/clients/twitter.png";
import discordImg from "../../assets/images/clients/discord.png";
import openseaImg from "../../assets/images/clients/opensea.png";
import x2y2Img from "../../assets/images/clients/x2y2.png";
import sudoswapImg from "../../assets/images/clients/sudoswap.png";
import nfttraderImg from "../../assets/images/clients/nft-trader.png";
import looksRareImg from "../../assets/images/clients/looksrare.png";
import blurImg from "../../assets/images/clients/blur.png";
import BlocksSection from "../Blocks/BlocksSection";
import FlexbotWalkthrough from "../Blocks/FlexbotWalkthrough";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients2: [
        { image: openseaImg },
        { image: twitterImg },
        { image: discordImg },
        { image: blurImg },
        { image: sudoswapImg },
        { image: x2y2Img },
        { image: nfttraderImg },
        { image: looksRareImg },
      ],
      responsive: {
        576: {
          items: 2,
        },
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section"
          id="clients"
          style={{ paddingTop: "140px" }}
        >
          <Container>
            <SectionTitle
              subtitle="BETTER BLOCKS IS PROUDLY INTEGRATED WITH"
              // title="What our Client Say"
              // desc="It will be as simple as occidental in fact, it will be Occidental."
            />

            <Row>
              {this.state.clients2.map((client, key) => (
                <Col xl={3} sm={6} key={key}>
                  <div className="client-images">
                    <img
                      src={client.image}
                      alt="client-img"
                      className="mx-auto img-fluid d-block"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <BlocksSection />
        <FlexbotWalkthrough />

        <section className="section" style={{ paddingTop: "35px" }}>
          <Container>
            <Row className="justify-content-center mb-3">
              <Col lg={7}>
                <div className="text-center text-white-50">
                  <h4 className="text-white landing-title">Trusted by You</h4>
                  <p className="landing-subtext">
                    Better Blocks came to life in March 2023, and since then
                    we've experienced an influx of users looking to utilise our
                    tools to enhance their Web3 projects
                  </p>
                </div>
              </Col>
            </Row>

            <Counter />
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
