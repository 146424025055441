import React, { useEffect, useState, useRef } from "react";
import FeatherIcon from "feather-icons-react";
import { Row, Col, Container } from "reactstrap";
import FlexbotWalkthroughImg from "../../assets/images/flexbot-walkthrough-poster.jpg";
import flexbotWalkthroughVid from "../../assets/images/flexbot-walkthrough.mp4";

function FlexVideo() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (videoRef.current && !isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  }, []);

  return (
    <video
      ref={videoRef}
      className="flexbot-video img-fluid mx-auto d-block"
      autoplay={true}
      loop={true}
      muted={true}
      playsinline={true}
      poster={FlexbotWalkthroughImg}
    >
      <source src={flexbotWalkthroughVid} type="video/mp4"></source>
    </video>
  );
}
const FlexbotWalkthrough = (props) => {
  return (
    <section className="section">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col lg={6} sm={8} className="order-12 order-md-12 order-lg-1">
            <div className="home-img flexbot mt-5 mt-lg-0">
              <div className="d-flex">
                <FlexVideo />
              </div>
            </div>
          </Col>
          <Col lg={6} className="order-1 order-md-1 order-lg-12">
            <div className="d-flex flex-column justify-space-between h-100 pb-4">
              <div>
                {/* <h1 className="hero-title mb-4">
                  Powerful Intuitive Tools To Maximise Your Project's Reach
                </h1> */}
                <h4 className="text-white landing-title">
                  Powerful Intuitive Tools To Maximise Your Project's Reach
                </h4>
                <p className="landing-subtext">
                  Proven solutions to automate and accelerate your marketing.
                  Work alongside your community to take the social media
                  spotlight.
                </p>
              </div>
            </div>
          </Col>
          {/* <Col lg={7}>
            <div className="text-center text-white-50">
              <h4 className="text-white landing-title">
                Powerful Intuitive Tools To Maximise Your Project's Reach
              </h4>
              <p className="landing-subtext">
                Proven solutions to automate and accelerate your marketing. Work
                alongside your community to take the social media spotlight.
              </p>
              <div className="d-flex">
                <FlexVideo />
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default FlexbotWalkthrough;
