import spotlightBotImg from "./assets/images/blocks/bots/spotlight-bot.png";
import salesBotImg from "./assets/images/blocks/bots/sales-bot.png";
import flexBotImg from "./assets/images/blocks/bots/spotlight-bot.png";
import snapshotToolImg from "./assets/images/blocks/bots/spotlight-bot.png";

export const Types = {
  Marketing: { title: `Marketing` },
  Minting: { title: `Minting` },
  Entertainment: { title: `Entertainment` },
  NFTs: { title: `NFTs` },
  Operational: { title: `Operational` },
};

export const Categories = {
  Twitter: { title: `Twitter`, class: `bg-info` },
  Discord: { title: `Discord`, class: `bg-discord` },
  ContentGeneration: { title: `Content Generation`, class: `bg-warning` },
  // Promotion: { title: `Promotion`, class: `bg-primary` },
};

export const Blocks = {
  NFTSpotlightBot: {
    title: `NFT Spotlight Bot`,
    url: `/blocks/spotlightbot`,
    img: spotlightBotImg,
    display: true,
    bullets: [
      `Create the perfect tweet to highlight your NFT's rarest features`,
      `Automate daily or weekly tweets showcasing random NFTs from your
    collection`,
      `Attract more attention to your collection`,
    ],
    categories: [
      Categories.Twitter,
      Categories.ContentGeneration,
      // Categories.Promotion,
    ],
    types: [Types.Marketing],
    released: true,
    expected: "Q2 2023",
  },
  NFTFlexBot: {
    title: `NFT Flex Bot`,
    url: `/blocks/flexbot`,
    img: flexBotImg,
    display: true,
    bullets: [
      `Create the perfect tweet to flex your NFT's rarest features`,
      `Automate daily or weekly tweets flexing your owned NFTs on social media`,
      `Attract more attention to your owned NFTs`,
    ],
    categories: [
      Categories.Twitter,
      Categories.ContentGeneration,
      // Categories.Promotion,
    ],
    types: [Types.Marketing],
    released: true,
    expected: "Q2 2023",
  },
  NFTSalesBot: {
    title: `NFT Sales Bot`,
    url: `/blocks/salesbot`,
    img: salesBotImg,
    display: true,
    bullets: [
      `Automatically post whenever an NFT in your collection is sold`,
      `Works seamlessly with Twitter and Discord`,
      `Attract more attention to your collection`,
    ],
    categories: [
      Categories.Twitter,
      Categories.Discord,
      Categories.ContentGeneration,
      // Categories.Promotion,
    ],
    types: [Types.NFTs],
    released: true,
    expected: "Q2 2023",
  },
  NFTSnapshotTool: {
    title: `NFT Snapshot Tool`,
    url: `/tools/snapshot`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [Categories.Data],
    types: [Types.NFTs],
    released: true,
    expected: "Q2 2023",
  },
  BitcoinOrdinalsDocs: {
    title: `Bitcoin Ordinals Documentation`,
    display: true,
    bullets: [
      `Documentation detailing the best ways of Minting NFTs on the Bitcoin Blockchain`,
    ],
    types: [Types.Minting],
    released: false,
    expected: "Q2 2023",
  },
  RoyaltyEnforcedDocs: {
    title: `Royalty Enforced Smart Contract Documentation`,
    display: true,
    bullets: [
      `Documentation detailing how to ensure your NFT Smart Contracts will receive royalties`,
    ],
    types: [Types.Minting],
    released: false,
    expected: "Q2 2023",
  },
  GasOptimisedDocs: {
    title: `Gas Optimized Smart Contract Documentation `,
    display: true,
    bullets: [
      `Documentation detailing how to deploy the most gas efficient Ethereum Smart Contracts`,
    ],
    types: [Types.Minting],
    released: false,
    expected: "Q2 2023",
  },
  CCC: {
    title: `CCC`,
    display: true,
    bullets: [
      `A creation tool allowing collectors the ability to use your art assets to create their own masterpieces`,
    ],
    types: [Types.Entertainment],
    released: false,
    expected: "Q3 2023",
  },
  BattleCards: {
    title: `Battle Cards`,
    display: true,
    bullets: [
      `A gaming solution based around playing cards that collectors can use to play and compete with one another`,
    ],
    types: [Types.Entertainment],
    released: false,
    expected: "Q3 2023",
  },
  NFTListingsBot: {
    title: `NFT Listings Bot`,
    display: true,
    bullets: [
      `An automated solution to display new listings from your collections on Discord and Twitter`,
    ],
    types: [Types.NFTs],
    released: false,
    expected: "Q2 2023",
  },
  AINFTArtUpscaler: {
    title: `AI NFT Art Upscaler`,
    display: true,
    bullets: [
      `An AI solution that enables users to upscale and enhance the quality their NFT artwork`,
    ],
    types: [Types.NFTs],
    released: false,
    expected: "Q3 2023",
  },
  NFTMetadataRefresh: {
    title: `NFT Metadata Refresh`,
    display: true,
    bullets: [
      `A tool that enables you to refresh the metadata for your NFT collection. Updating both images and rarity to reflect any recent changes.`,
    ],
    types: [Types.NFTs],
    released: false,
    expected: "Q3 2023",
  },
  NFTMultisendAirdrop: {
    title: `NFT Multisend/Airdrop`,
    display: true,
    bullets: [
      `A tool that enables you to send or airdrop multiple NFTs to a list of wallets with one click!`,
    ],
    types: [Types.NFTs],
    released: false,
    expected: "Q3 2023",
  },
  DowntimeNotifier: {
    title: `Downtime Notifier`,
    display: true,
    bullets: [
      `A tool that can be intergrated into your existing architecture that sends a email, telegram message, discord message when your server is experiencing downtime.`,
    ],
    types: [Types.Operational],
    released: false,
    expected: "Q3 2023",
  },
  VotingPortal: {
    title: `Voting Portal`,
    display: true,
    bullets: [
      `A tool that enables community members to cast votes on important decisions using their NFTs.`,
    ],
    types: [Types.Operational],
    released: false,
    expected: "Q3 2023",
  },
  TheGoodList: {
    title: `The Good List`,
    display: true,
    bullets: [
      `A tool that enables you to gather addresses that have selected to pay OS commissions.`,
    ],
    types: [Types.Operational],
    released: false,
    expected: "Q2 2023",
  },
};
