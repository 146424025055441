import React, { Component, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { motion } from "framer-motion";
//Import Images
import homeImg from "../../assets/images/home-img.png";
import homeVidOverlay from "../../assets/images/hero-video-overlay.png";
import techBgImg from "../../assets/images/bg-tech.jpg";

import zeev from "../../assets/images/team/zeev.png";
import imran from "../../assets/images/team/imran.png";
import josh from "../../assets/images/team/josh.png";
import kayla from "../../assets/images/team/kayla.png";
import luke from "../../assets/images/team/luke.png";
import matt from "../../assets/images/team/matt.png";
import nick from "../../assets/images/team/nick.png";
import sarah from "../../assets/images/team/sarah.png";

const teamMembers = [
  {
    name: "Zeev Klein",
    role: "Founder",
    img: zeev,
    bgOpacity: 0.9,
  },
  {
    name: "Imran Haq",
    role: "Product & Strategy",
    img: imran,
    bgOpacity: 0.4,
  },
  {
    name: "Nick Fontova",
    role: "CHIEF MARKETING OFFICER",
    img: nick,
    bgOpacity: 0.38,
  },
  {
    name: "Matt Jones",
    role: "Engineer",
    img: matt,
    bgOpacity: 1,
  },
  {
    name: "Luke Shepard",
    role: "Engineer",
    img: luke,
    bgOpacity: 0.75,
  },
  {
    name: `Josh "Peace Frog"`,
    role: "Community Manager",
    img: josh,
    bgOpacity: 0.4,
  },
  {
    name: "Kayla Schoerie",
    role: "MARKETING & COMMUNITY",
    img: kayla,
    bgOpacity: 0.9,
  },
  {
    name: "Sarah Clapp",
    role: "CREATIVE DIRECTOR",
    img: sarah,
    bgOpacity: 0.46,
  },
];

function toTitleCase(str) {
  return str.replace(/\b\w+/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="about-us-section" id="home">
          <Container>
            <Row className="justify-content-center">
              <Col lg={12}>
                <motion.div
                  animate={{
                    scale: [0, 1.07, 1],
                    opacity: [0, 1],
                    x: [-5, 0],
                  }}
                  className="h-100"
                >
                  <div className="d-flex flex-column justify-space-between h-100 pb-4">
                    <div>
                      <h1 className="hero-title mb-4">
                        {/* Build Better Web3 */}
                        About Us
                        {/* <span className="text-primary">Invoza</span> */}
                      </h1>
                      <p>
                        We are delighted to introduce Better Blocks, a company
                        dedicated to making web3 tools for artists, creators,
                        and founders of web3 projects. Better Blocks was created
                        by the web3/metaverse team at Landmark Ventures in NYC,
                        and is supported by a team of experienced professionals
                        in web3.
                      </p>
                      <p>
                        We are on a mission to be the largest creator of value
                        for Web3 Projects & Communities. For founders, builders,
                        and collectors our easy to use platform delivers
                        gamechanging improvement in project utility,
                        extensibility, and scalability. Join us as we make the
                        blockchain projects you love… better!
                      </p>
                      <h1 className="hero-title mb-4">
                        {/* Build Better Web3 */}
                        Meet the Team
                        {/* <span className="text-primary">Invoza</span> */}
                      </h1>
                      <p>
                        At Better Blocks, we have full-time developers,
                        marketers, and community managers who are passionate
                        about making a difference in web3. Our team is supported
                        by Landmark's HR team, Events Team, and benefits from
                        years of Landmark building relationships and
                        partnerships with the top Fortune 500 companies.
                      </p>
                      <p>
                        Our expertise includes not only the creation, but
                        implementation, of full off-chain token and marketplace
                        integration, loyalty and rewards via blockchain, an
                        array of automated marketing tools, and more. We are
                        excited to not only level the playing field by
                        democratizing the spaces with an impressive offering of
                        free web3 tools, but to co-create and customize tools
                        based on the needs and feedback from founders, artists,
                        collectors, and creators.
                      </p>
                    </div>
                    <div className="team-container">
                      <div className="row">
                        {teamMembers.map((member, index) => {
                          return (
                            <div className="col-3" key={index}>
                              <motion.div
                                animate={{
                                  scale: [0.5, 1.05, 1],
                                  opacity: [0, 0.5, 1],
                                  y: [-5, 0],
                                }}
                                transition={{ delay: index * 0.1 }}
                              >
                                <div className="team-member">
                                  <div className="pic">
                                    <div
                                      className="bg"
                                      style={{ opacity: member.bgOpacity }}
                                    ></div>
                                    <img
                                      src={member.img}
                                      className="img-fluid face-pic"
                                    />
                                    <img
                                      src={member.img}
                                      className="img-fluid"
                                      style={{ opacity: 0 }}
                                    />
                                  </div>
                                  <div className="name">{member.name}</div>
                                  <div className="role">
                                    {toTitleCase(member.role)}
                                  </div>
                                </div>
                              </motion.div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* <div className="mt-1">
                      <a
                        href="https://app.betterblocks.io"
                        className="btn btn-primary btn-lg mt-2 mr-2"
                      >
                        Get Started Now
                      </a>
                    </div> */}
                  </div>
                </motion.div>
              </Col>
            </Row>
          </Container>
          <div style={{ height: "100px" }}></div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
