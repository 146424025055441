import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Media } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import logolight from "../../assets/images/logo-dark.png";

//Import Footer link
import FooterLinks from "./footer-links";
import SubscribeFooter from "../Subscribe/SubscribeFooter";

class Footer extends Component {
  state = {
    links1: [
      { link: "https://app.betterblocks.io/", title: "BetterBlocks App" },
    ],
    blocks: [
      {
        link: "https://app.betterblocks.io/blocks/salesbot",
        title: "Sales Bot",
      },
      {
        link: "https://app.betterblocks.io/blocks/spotlightbot",
        title: "Spotlight Bot",
      },
      { link: "https://app.betterblocks.io/blocks/flexbot", title: "Flex Bot" },
      {
        link: "https://app.betterblocks.io/tools/snapshot",
        title: "Snapshot Tool",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              <Col lg={3} sm={6}>
                <div>
                  <div>
                    <Link to="#">
                      <img src={logolight} alt="" height="32" />
                    </Link>
                  </div>
                  <p
                    className="footer-newsletter-subtext"
                    style={{ marginTop: "8px" }}
                  >
                    A user-friendly Web3 development platform.
                    {/* <br></br>No devs, no problem. */}
                  </p>
                  <div className="footer-newsletter-title">Stay In Touch</div>
                  <div className="footer-newsletter-subtext">
                    Sign up to our newsletter
                  </div>
                  <SubscribeFooter />
                </div>
              </Col>
              <Col lg={{ size: 2 }} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">Resources</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {this.state.links1.map((fLink, key) => (
                      <li key={key}>
                        {!fLink.link.startsWith("http") ? (
                          <Link to={fLink.link}>{fLink.title}</Link>
                        ) : (
                          <a href={fLink.link} target="_blank">
                            {fLink.title}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col lg={2} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">Blocks</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {this.state.blocks.map((fLink, key) => (
                      <li key={key}>
                        {!fLink.link.startsWith("http") ? (
                          <Link to={fLink.link}>{fLink.title}</Link>
                        ) : (
                          <a href={fLink.link} target="_blank">
                            {fLink.title}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col lg={2} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">Socials</h5>
                  <ul className="list-unstyled footer-list-menu">
                    <li>
                      <a
                        href="https://twitter.com/BetterBlocksio"
                        target="_blank"
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://discord.com/invite/vPSuTs3qz4"
                        target="_blank"
                      >
                        Discord
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/better-blocks"
                        target="_blank"
                      >
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col lg={2} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">Contact Us</h5>

                  <div>
                    {/* <Media>
                      <i>
                        <FeatherIcon
                          icon="twitter"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i>
                      <Media body>
                        <a
                          target="_blank"
                          href="https://twitter.com/BetterBlocksio/"
                        >
                          <p>@BetterBlocksio</p>
                        </a>
                      </Media>
                    </Media> */}
                    <Media>
                      {/* <i>
                        <FeatherIcon
                          icon="mail"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i> */}
                      <Media body>
                        <a href="mailto:contact@betterblocks.io">
                          <p>General Enquiry</p>
                        </a>
                      </Media>
                    </Media>
                    <Media>
                      {/* <i>
                        <FeatherIcon
                          icon="mail"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i> */}
                      <Media body>
                        <a href="mailto:contact@betterblocks.io">
                          <p>Sales</p>
                        </a>
                      </Media>
                    </Media>
                    <Media>
                      {/* <i>
                        <FeatherIcon
                          icon="mail"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i> */}
                      <Media body>
                        <a href="mailto:press@betterblocks.io">
                          <p>Press</p>
                        </a>
                      </Media>
                    </Media>
                    <Media>
                      {/* <i>
                        <FeatherIcon
                          icon="mail"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i> */}
                      <Media body>
                        <a
                          href="https://discord.com/invite/vPSuTs3qz4"
                          target="_blank"
                        >
                          <p>Support</p>
                        </a>
                      </Media>
                    </Media>
                    {/* <Media>
                      <i>
                        <FeatherIcon
                          icon="phone"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i>
                      <Media body>
                        <p>0123-456-789</p>
                      </Media>
                    </Media> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        {/* Render footer links */}
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
