import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  Collapse,
  NavItem,
  NavLink,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import twitterImg from "../../assets/images/twitter-purple.svg";
import discordImg from "../../assets/images/discord-purple.svg";

const navItems = [
  { id: 1, idnm: "home", navheading: "Home", navUrl: "/" },
  { id: 2, idnm: "about", navheading: "About", navUrl: "/about" },
];

const NavbarPage = (props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  let targetId = navItems.map((item) => item.idnm);

  // The current location.
  // console.log("location", location);

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        className={`navbar-custom sticky sticky-dark navbar-abs-top ${props.navClass}`}
      >
        <NavbarBrand className="logo text-uppercase" href="/">
          {props.imglight === true ? (
            <img src={logolight} alt="" className="logo-light" height="40" />
          ) : (
            <img src={logodark} alt="" className="logo-dark" height="38" />
          )}
        </NavbarBrand>

        <Collapse id="navbarCollapse" isOpen={true} navbar>
          <Nav navbar className="mr-auto navbar-center" id="mySidenav">
            {navItems.map((item, key) => (
              <NavItem
                key={key}
                className={
                  item.navUrl.toLowerCase() === location.pathname.toLowerCase()
                    ? "active"
                    : ""
                }
              >
                <NavLink to={item.navUrl} href={item.navUrl}>
                  {item.navheading}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <a href="https://app.betterblocks.io">
            <div
              className="btn-rounded navbar-btn btn-outline btn-outline-primary"
              style={{ marginRight: `0.5rem` }}
              onClick={() => {
                window.open("https://app.betterblocks.io", "_self");
              }}
            >
              Go to App
            </div>
          </a>
        </Collapse>
      </Navbar>
    </React.Fragment>
  );
};

export default NavbarPage;
