import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import BlocksSection from "../../components/Blocks/BlocksSection";
import Features from "../../components/Features/feature";
import Clients from "../../components/Client/clients";
import Pricing from "../../components/Pricing/pricing";
import Subscribe from "../../components/Subscribe/Subscribe";
import Footer from "../../components/Footer/footer";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "about", navheading: "About" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navclassName={this.state.navClass}
          imglight={this.state.imglight}
        />
        <div className="homepage">
          <div className="home-gradient-one"></div>
          <div className="home-gradient-two"></div>
          <div className="home-gradient-two-b"></div>
          <div className="home-gradient-three"></div>
          <div className="home-gradient-three-b"></div>
          <div className="home-gradient-four"></div>
          <div className="home-gradient-four-b"></div>
          <div className="home-gradient-five"></div>
          {/* Importing section */}
          <Section />
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default AboutUs;
