import React, { useState } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import toast from "react-hot-toast";
import { validate } from "react-email-validator";
import ApiService from "../../ApiService";
import { motion } from "framer-motion";

const Subscribe = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    const email = event.target.value.trim();
    setEmail(email);
  };

  const handleEmailSubmit = async () => {
    if (!validate(email)) {
      toast.error("Invalid Email");
      return;
    }

    // submit email to backend
    const api = new ApiService();
    const { error, msg } = await api.post(`email/submit`, { email });
    if (error) {
      toast.error(msg);
      return;
    } else if (!error && msg) {
      toast.success(msg);
      setEmail("");
    }
  };

  return (
    <React.Fragment>
      <section className="section" style={{ paddingBottom: "118px" }}>
        <Container className="mw-800">
          <Row className="justify-content-center">
            <Col xl={8} lg={10}>
              <motion.div
                animate={{
                  scale: [0.8, 1.07, 1],
                  opacity: [0, 1],
                }}
              >
                <div className="text-center">
                  {/* <h5 className="text-primary text-uppercase small-title">Subscribe</h5> */}
                  <h4 class="text-white mb-3">Be the first to hear</h4>
                  {/* <p>It will be as simple as occidental in fact, it will be Occidental.</p> */}
                </div>
                <div className="text-center">
                  <div className="subscribe">
                    <Form>
                      <Row>
                        <Col md={7}>
                          <div>
                            <Input
                              type="text"
                              className="form-control email-input"
                              placeholder="Email Address"
                              onChange={handleEmailChange}
                              value={email}
                            />
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="mt-3 mt-md-0">
                            <Button
                              onClick={handleEmailSubmit}
                              color="primary"
                              block
                              className="email-submit"
                            >
                              Join Mailing List
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Subscribe;
