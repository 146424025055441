import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class SectionTitle extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="text-center mb-3">
              {this.props.subtitle ? (
                <h5 className="col-grey text-uppercase small-title">
                  {this.props.subtitle}
                </h5>
              ) : null}
              {this.props.title ? (
                <h4 className="mb-3">{this.props.title}</h4>
              ) : null}
              {this.props.desc ? <p>{this.props.desc}</p> : null}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SectionTitle;
