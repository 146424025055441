import React, { Component, useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Row, Col, Container } from "reactstrap";
import { Blocks, Types, Categories } from "../../blocks";
import { motion } from "framer-motion";

const BlockDisplay = (props) => {
  return (
    <React.Fragment>
      {this.props.services.map((service, key) => (
        <Col xl={4} sm={6} key={key}>
          <div className="text-center p-4 mt-3">
            <h5 className="font-18">{service.title}</h5>
            <p className="mb-0">{service.desc}</p>
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

const BlocksSection = (props) => {
  const [selectedType, setSelectedType] = useState(Types["Marketing"]);
  const [filteredBlocks, setFilteredBlocks] = useState([]);
  useEffect(() => {
    const _filteredBlocks = Object.values(Blocks).filter((block) =>
      block.types.includes(selectedType)
    );
    setFilteredBlocks(_filteredBlocks);
    console.log(`filteredBlocks`, _filteredBlocks);
  }, [selectedType]);

  const getBlockExpectedClass = (released, expected) => {
    if (released) {
      return "released";
    }
    if (expected === "Q2 2023") {
      return "hot";
    }
    if (expected === "Q3 2023") {
      return "med";
    }
    if (expected === "Q4 2023") {
      return "low";
    }
    return "low";
  };

  const getBlockContainerClass = (released, expected) => {
    if (released) {
      // return "hvr-shrink";
    }
    return "";
  };

  const getBlockExpectedText = (released, expected) => {
    if (released) {
      return "LIVE";
    }
    return expected;
  };

  const sortBlocksByExpected = (blocks) => {
    const orderMap = {
      released: 0,
      "Q2 2023": 1,
      "Q3 2023": 2,
      "Q4 2023": 3,
      "Q1 2024": 4,
      "Q2 2024": 5,
      "Q3 2024": 6,
      "Q4 2024": 7,
      unknown: 8,
    };

    return blocks.sort((a, b) => {
      const aOrder = orderMap[a.expected] || orderMap.unknown;
      const bOrder = orderMap[b.expected] || orderMap.unknown;
      return aOrder - bOrder;
    });
  };
  const sortedBlocks = sortBlocksByExpected(filteredBlocks);

  return (
    <section className="section">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col lg={8}>
            <div className="text-center text-white-50">
              <h4 className="text-white">Get Started with Better Blocks</h4>
              <p className="landing-subtext">
                The Blocks below illustrate our current and planned offers.
                <br></br>
                <a href="mailto:contact@betterblocks.io">Contact Sales</a> to
                make an additional request.
              </p>
              <div className="b-blocks-display d-flex justify-space-between row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5">
                {Object.keys(Types).map((type, index) => (
                  <div key={type} className="col d-flex justify-content-center">
                    <div
                      className={`btn-outline-primary b-tools-btn${
                        Types[type] === selectedType ? " selected" : ""
                      }`}
                      onClick={() => setSelectedType(Types[type])}
                    >
                      {Types[type].title}
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                {sortedBlocks.map((block, index) => {
                  const blockExpectedClass = getBlockExpectedClass(
                    block.released,
                    block.expected
                  );
                  const blockContainerClass = getBlockContainerClass(
                    block.released,
                    block.expected
                  );

                  const BlockContainer = () => (
                    <div
                      className={`block-container ${blockExpectedClass} ${blockContainerClass}`}
                    >
                      <div className="block-top-container">
                        <div className="block-title">{block.title}</div>
                        <div className={`block-expected ${blockExpectedClass}`}>
                          {getBlockExpectedText(block.released, block.expected)}
                        </div>
                      </div>
                      <div className="block-desc">{block.bullets[0]}</div>
                    </div>
                  );

                  return (
                    <React.Fragment key={block.title}>
                      <div className="col-6 col-md-4">
                        <motion.div
                          animate={{
                            scale: [0.5, 1.05, 1],
                            opacity: [0, 0.5, 1],
                            y: [-5, 0],
                          }}
                          transition={{ delay: index * 0.1 }}
                        >
                          {block.url ? (
                            <a
                              target="_blank"
                              href={`https://app.betterblocks.io${block.url}`}
                            >
                              <BlockContainer />
                            </a>
                          ) : (
                            <BlockContainer />
                          )}
                        </motion.div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              {/* <p>
                    Better Blocks has a wide range of blocks which 
                  </p> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlocksSection;
